import { ApiCollection }   from 'Collections/ApiCollection';
import DepositModel        from 'Models/invoice/DepositModel';
import ContractModel       from 'Models/sales/ContractModel';
import AbstractModelXStore from 'stores/AbstractModelXStore';

export default class DepositDashboardStore extends AbstractModelXStore {

	public collectionContract = new ApiCollection(ContractModel);
	public deposit = new DepositModel();

	public async fetchAsync(id: id) {
		this.clear();

		await this.deposit.setId(id).fetch();

		await this.collectionContract.listBy([this.deposit.billingGroupUrn], 'billingGroupUrn');
	}

	public get contract() {
		return this.collectionContract.first();
	}
}